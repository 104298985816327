@import url("https://fonts.googleapis.com/css2?family=Space+Mono&family=Work+Sans&display=swap");

body {
  margin: 0;
  padding: 0;
  font-family: "Work Sans", sans-serif;
  font-size: 14px;
  line-height: 20px;
  color: white;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

h3 {
  font-family: "Space Mono", monospace;
}

a {
  font-weight: bolder;
  color: white;
  text-decoration: none;
  position: relative;
}

a:hover:before {
  content: "";
  position: absolute;
  bottom: -4px;
  left: 0;
  background-repeat: repeat-x;
  background-size: 400px 400px;
  width: 100%;
  height: 2px;
  background-position: center bottom;
  background-image: linear-gradient(
    to bottom right,
    #ee0979 0%,
    #ff6a00 20%,
    #a8ff78 40%,
    #78ffd6 70%,
    #00d2ff 80%,
    #3a7bd5 100%
  );
}

.container {
  background-color: black;
  border: 8px solid rgba(0, 0, 0, 0.99);
  border-image: linear-gradient(
    to bottom right,
    #ee0979 0%,
    #ff6a00 20%,
    #a8ff78 40%,
    #78ffd6 60%,
    #00d2ff 80%,
    #3a7bd5 100%
  );
  border-image-slice: 1;
}
